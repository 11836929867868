







import { Component, Mixins, Prop } from 'vue-property-decorator'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { ProductPageStore } from '@elitepage/store/product-page'
import { AbstractFinishPaypal } from '~/mixins/finishPaypal'

@Component({ components: { VLoadSpinner } })
export default class EliteFinishPaypal extends Mixins(AbstractFinishPaypal) {}
