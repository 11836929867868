import { Component, Mixins } from 'vue-property-decorator'
import { AbstractCartPaymentForm } from '@/mixins/abstract-cart-payment-form'
import { paypalCaptureOrder } from '@/api/payment'

@Component
export class AbstractFinishPaypal extends Mixins(AbstractCartPaymentForm) {
    async captureOrder() {
        try {
            this.SET_LOADING_STATE(true)
            const approvedPaypalOrderId = this.$route.query.token.toString()
            const orderId = parseInt(this.$route.params.orderId, 10)
            const orderData = await paypalCaptureOrder(orderId, approvedPaypalOrderId)
            this.onCompletedPayment(orderData)
        } catch (error: any) {
            this.paymentError = error
            console.error(error)
        } finally {
            this.SET_LOADING_STATE(false)
        }
    }

    created() {
        this.captureOrder()
    }
}
